import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import "../pages/home.css";
import Hermes from "../assets/Hermes-award-2022.png";
import Muse from "../assets/Muse-silver-award-2022.png";

export const Awards = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="awards-banner" id="home">
      <div id="Awards">
        <h2 className="awards-heading">Awards</h2>
        <div className="awards-content">
          <Col>
            <img src={Hermes} alt="Hermes Gold Award" />
            <p>
              Role: Developer <br />
              <a href="https://enter.hermesawards.com/entry/fusion-implant-center-website/">
                View Award
              </a>
            </p>
          </Col>
          <Col>
            <img src={Muse} alt="Muse Silver Award" />
            <p>
              Role: Developer <br />
              <a href="https://museaward.com/winner-info.php?id=221661">
                View Award
              </a>
            </p>
          </Col>
        </div>
      </div>
    </section>
  );
};
