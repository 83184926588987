import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Skills from "./components/Skills";
import Layout from "./components/Layout";
import { Helmet } from "react-helmet";
import { HashRouter } from "react-router-dom";

library.add(fab, faCaretLeft, faCaretRight);

function App() {
  return (
    <div className="main">
      <Helmet>
        <title>Brendan Blanchard - Web Developer</title>
        <meta name="description" content="Brendan is a developer who incorporates his marketing experience to drive huge traffic and attention to his work."/>
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/skills" element={<Skills />} />
          </Routes>
      </Layout>
    </div>
  );
}

export default App;
