import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Aos from "aos";
import "aos/dist/aos.css";

const ContactForm = () => {
  // const [status, setStatus] = useState("Submit");
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setStatus("Sending...");
  //   const { name, email, message } = e.target.elements;
  //   let details = {
  //     name: name.value,
  //     email: email.value,
  //     message: message.value,
  //   };
  //   try {
  //     const res = await axios.post("/server");
  //     console.log(res.data);
  //     setStatus("Sent!");
  //   } catch (err) {
  //     console.error(err);
  //     setStatus("Oops! Try again.");
  //   }
  // };

    useEffect(() => {
      Aos.init({ duration: 2000 });
    }, []);
  const [state, handleSubmit] = useForm("xqkorerg");

  if (state.succeeded) {
    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return <div id="submission-box" data-aos="fade-left"><p>Thank you for your interest. Talk to you soon!</p></div>;
  }

  return (
    // <form onSubmit={handleSubmit} className="animate__animated animate__zoomIn">
    //   <Col>
    //     <div id="namediv">
    //       <label htmlFor="name">Name:</label>
    //       <input type="text" id="name" required />
    //     </div>
    //   </Col>
    //   <Col>
    //     <div id="emaildiv">
    //       <label htmlFor="email">Email:</label>
    //       <input type="email" id="email" required />
    //     </div>
    //   </Col>
    //   <div id="messagediv">
    //     <label htmlFor="message">How Can I Help?</label>
    //     <textarea type="message" id="message" required />
    //   </div>
    //   <button type="submit" id="submit-btn">
    //     {status}
    //   </button>
    // </form>
    <form onSubmit={handleSubmit}>
      <div id="namediv">
        <label htmlFor="email">Name</label>
        <input id="name" type="text" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <label htmlFor="email">Email Address</label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <label htmlFor="message">How Can I Help?</label>
      <textarea id="message" name="message" required></textarea>
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button type="submit" id="submit-btn" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
