import "./contact.css";
import ContactForm from "../components/Form";
import "animate.css";

const Contact = () => {
  return (
    <div>
    <section className="contact" id="contact">
      <h2 className="animate__animated animate__zoomIn">Get In Touch</h2>
      <h3 className="animate__animated animate__zoomIn">Contact me to bring your idea to life!</h3>
    </section>
    <section className="contact_page-form">
    <ContactForm />
    </section>
    </div>
  );
};

export default Contact;
