import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import "./skills.css";
import "animate.css";

const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-header">
              <h2 className="animate__animated animate__zoomIn">
                What Can I Do?
              </h2>
              <p className="animate__animated animate__zoomIn">
                Knowing that every project and client is different, I see every
                opportunity as an adjustment to my level, like the volume knob
                to your radio. I've led roles ranging from taking director lead
                on projects and oversight, to coordinating with project
                managers, to being the sole force of creating the product and
                vision of my clients.
              </p>
              <p>Something I missed that you are looking for? <a href="/#/contact">Contact me</a>!</p>
            </div>
            <div className="skill-box">
              <Col>
                <h3 className="skills-list">Languages</h3>
                <ul>
                <li>PHP</li>
                <li>HTML</li>
                <li>Javascript</li>
                <li>CSS3 (SCSS)</li>
                <li>SQL</li>
              </ul>
              </Col>
              <Col>
                <h3 className="skills-list">Frameworks</h3>
                <ul>
                <li>React (My website is built off this)</li>
                <li>Angular</li>
                <li>Bootstrap</li>
                <li>ASP.NET</li>
              </ul>
              </Col>
              <Col>
                <h3 className="skills-list">Extra Goodies</h3>
                <ul>
                <li>Photoshop</li>
                <li>Wordpress</li>
                <li>DNN</li>
                <li>SEO</li>
                <li>And More...</li>
              </ul>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Skills;
