import ParticleMain from "../components/particles/Particle";
import "./home.css";
import "animate.css";
import React, { useState, useEffect } from "react";
import { faSection } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Skills } from "../components/Skills";
import { Banner } from "../components/Banner";
import { AboutMe } from "../components/AboutMe";
import { Awards } from "../components/Awards";

const Home = () => {
  return (
    <div className="home-main">
      <ParticleMain />
      <Banner />
      <AboutMe />
      <Awards />
    </div>
  );
};

export default Home;
