import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import "../pages/home.css";

export const AboutMe = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="aboutme-banner" id="home">
      {/* <Container>
        <Row> */}
      {/* <Col> */}
      <div data-aos="fade-left" id="AboutMe">
        <h3 className="about-heading">Who Am I?</h3>
        <p>
          I am a developer with a passion for creating awesome and interactive
          UI. With marketing experience, I understand what is necessary to bring
          attention to consumers in order to drive traffic, as well as keep them
          engaged.
        </p>
        <p>
          On a <a href="/#/skills/">multitude of platforms</a>, I've worked with
          clients to take their web presence to the next level.
        </p>
        <p>Got an idea you need brought to life? <a href="mailto:brendanjblanchard@gmail.com">Email me</a>, or contact me via <a href="https://www.linkedin.com/in/brendan-blanchard-98140811b" target="_blank">LinkedIn</a>!</p>
      </div>
      {/* </Col> */}
      {/* </Row>
      </Container> */}
    </section>
  );
};
