import {Nav, MobileNav} from "./mainnav/Nav";
import { Timeline, Tween } from "react-gsap";
import React, { useState } from "react";
import "./layout.css";

const Layout = (props) => {
  const [navClosed, NavCollapse] = useState(true);

  const navSlide = () => {
    NavCollapse(!navClosed);
  };

  const [mnavClosed, mNavCollapse] = useState(true);

  const mnavSlide = () => {
    mNavCollapse(!mnavClosed);
  };

  return (
    <div>
      <div id="top">
        <div className="main-wrapper">
          <div id="menu">
            <Nav navSlide={navSlide} navClosed={navClosed} />
            <MobileNav mnavSlide={mnavSlide} mnavClosed={mnavClosed} />
          </div>
          <div className={navClosed ? "main expanded" : "main collapsed"}>
            <header id="header"></header>

            <div id="content-main">{props.children}</div>
            <footer id="footer"></footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
