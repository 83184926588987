import "../pages/home.css";
import "animate.css";
import React, { useState, useEffect } from "react";
import { faSection } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { SplitText, LetterWrapperProp } from "@cyriacbr/react-split-text";
import { Skills } from "../components/Skills";

const LetterWrapping = ({ children }) => {
  const [flipper, SetAnimated] = useState(false);
  return (
    <span
      className={flipper ? "SplitText flipper" : "SplitText noflip"}
      onMouseEnter={() => SetAnimated(() => true)}
      onAnimationEnd={() => SetAnimated(() => false)}
    >
      {children}
    </span>
  );
};

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-left">
          <Col xs={12} md={6} xl={7}>
            <div className="head-intro animate__animated animate__fadeInLeft">
              <SplitText LetterWrapper={LetterWrapping}>
                Hi,I'm Brendan
              </SplitText>
            </div>
            <div className="animate__animated animate__fadeInRight" id="sub-head">
              <h2>Web Developer</h2>
            </div>
            <a href="/#/contact"><button id="head-contact-button">Contact Me</button></a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
